// Function to handle menu toggle button click event
function handleMenuToggle() {
	var menuToggleButton = document.querySelector(".menu-toggle");

	menuToggleButton.addEventListener("click", function (e) {
		e.preventDefault();
		// Add your code here to handle the menu toggle button click event
	});
}

// Function to observe changes in the mmenuBodyWrapper element and update the hamburger class accordingly
function observeMmenuBodyWrapper() {
	var mmenuBodyWrapper = document.querySelector(".mm-wrapper");
	var hamburger = document.querySelector(".hamburger");
	// Create a new MutationObserver
	var observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			// Check if mmenuBodyWrapper has the "mm-wrapper--opened" class
			if (mmenuBodyWrapper.classList.contains("mm-wrapper--opened")) {
				// Add the "is-active" class to the hamburger element
				hamburger.classList.add("is-active");
			} else {
				// Remove the "is-active" class from the hamburger element
				hamburger.classList.remove("is-active");
			}
		});
	});

	// Observe changes in the mmenuBodyWrapper element and its attributes
	observer.observe(mmenuBodyWrapper, { attributes: true });
}

function StickyHeaderHandleScroll() {
	var header = document.querySelector(".header-absolute");
	if (header === null) return;
	window.addEventListener("scroll", function () {
		var site_logo = document.querySelector(".site-logo");
		if (window.scrollY > 0) {
			header.classList.add("header-sticky");
			header.classList.remove("header-absolute");
			site_logo.src = site_logo.getAttribute("data-default-logo");
		} else {
			header.classList.remove("header-sticky");
			header.classList.add("header-absolute");
			site_logo.src = site_logo.getAttribute("data-white-logo");
		}
	});
}

function openSubMenu() {
	var menuItems = document.querySelectorAll(
		".menu-item-has-children a.parent-menu-link"
	);
	menuItems.forEach(function (menuItem) {
		menuItem.addEventListener("click", function (e) {
			e.preventDefault();

			var subMenuWrapper = menuItem.parentElement.querySelector(
				"#menu-sub-menu--wrapper"
			);

			subMenuWrapper.classList.add("menu-sub-menu--wrapper--open");
			document.querySelector("body").style.overflow = "hidden";
			subMenuWrapper.style.top = "-40px";
		});
	});
}

function closeSubMenu() {

	var closeButtons = document.querySelectorAll(".sub-menu-close");
	var submenuoverlay = document.querySelectorAll(".sub-menu-overlay");

	closeButtons.forEach(function (closeButton) {
		closeButton.addEventListener( "click", close_submenu_handler );
	});

	submenuoverlay.forEach(function (closeButton) {
		closeButton.addEventListener( "click", close_submenu_handler );
		document.addEventListener( "keydown", close_submenu_handler );
	});

}

// Closes submenu
function close_submenu_handler(e){

	//handles pressing ESC key on keyboard
	if( e.type == "keydown" && e.key != "Escape" ){
		return;
	}

	var body = document.querySelector("body");
	body.style.removeProperty("overflow");

	var subMenuWrapper = document.querySelectorAll("#menu-sub-menu--wrapper");
	// subMenuWrapper.style.removeProperty("top");
	subMenuWrapper.forEach(function (subMenu) {
		subMenu.style.removeProperty("top");
		subMenu.classList.remove("menu-sub-menu--wrapper--open");
	});

	e.preventDefault();
}

document.addEventListener("DOMContentLoaded", () => {
	const logoElement = document.querySelector(".mobile-menu-site-logo");
	var defaultLogo = logoElement.getAttribute("data-default-logo");

	logoElement.src = defaultLogo;

	const phoneIconLink = document.querySelector(".phone-icon-link");
	const topMenu = document.querySelector("#mobile-menu-reviews");
	new Mmenu("#nav-responsive-menu", {
		offCanvas: {
			position: "top",
		},
		theme: "white",
		navbars: [
			{
				position: "top",
				content: [topMenu, logoElement, phoneIconLink, "close"],
			},
		],
		setSelected: {
			hover: true,
			parent: true,
		},
	});

	//Call the handleMenuToggle function
	handleMenuToggle();
	// Call the observeMmenuBodyWrapper function
	observeMmenuBodyWrapper();

	openSubMenu();
	closeSubMenu();
	StickyHeaderHandleScroll();
});


